import moment from "moment";
import * as XLSX from "xlsx";

const { REACT_APP_RIOT_URL, REACT_APP_RIOT_API_USER, REACT_APP_RIOT_API_TOKEN } = process.env;

export const getId = (pathname) => {
  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];
  const id = parseInt(lastPart);

  if (!isNaN(id)) return id;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatDate = (value) => {
  if (!value || value === 'null') return;

  const date = moment(value);
  return date.format("MM/DD/YY");
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

export const buildQueryString = (path, params) => {
  const queryString = Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  return `${path}?${queryString}`;
};

export const checkUrl = (url, template) => {
  return url.startsWith(template);
}

export const authHeaderOrthus = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return { "x-user-id": user.id, "x-access-token": user.token, "x-data-silo-code": user.data_silo_codes };
  } else {
    return {};
  }
}

export const authHeader = () => {
  let headers = {"X-Auth-Token": REACT_APP_RIOT_API_TOKEN}
  let imprints = '';
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    headers = { "X-User-Id": user.id, "X-Person-Token": user.token, "X-Data-Silo-Code": 'cgo', ...headers };
    imprints = user.imprint_ids;
  } else {
    
  }
  return { headers, imprints };
}


export const generateDateRange = (start, end) => {
  start = moment(start);
  end = moment(end);
  let dates = [start.format('DD-MM-YYYY')];
  
  while(start.add(1, 'days').diff(end) <= 0) {
      dates.push(start.clone().format('DD-MM-YYYY'));
  }
  return dates;
}

export const generateMonthRange = (start, end) => {
  start = moment(start);
  end = moment(end);
  let dates = [start.format('MMM-YYYY')];
  
  while(start.add(1, 'month').diff(end, 'months') <= 0) {
      dates.push(start.clone().format('MMM-YYYY'));
  }
  return dates;
}

export const exportToXLSX = (sheets, fileName = "export.xlsx") => {
  if (!Array.isArray(sheets) || sheets.length === 0) {
    console.error("No sheets provided for export.");
    return;
  }

  const wb = XLSX.utils.book_new();

  sheets.forEach(({ name, data }) => {
    if (!name || !Array.isArray(data)) {
      console.error("Invalid sheet format: Each sheet must have a name and data array.");
      return;
    }
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, name);
  });

  XLSX.writeFile(wb, fileName);
};
