import React from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb } from 'antd';
import css from './Breadcrumbs.module.css';

function itemRender(currentRoute, params, items, paths) {
  const isLast = currentRoute?.path === items[items.length - 1]?.path;

  return isLast ? (
	<span>{currentRoute.title}</span>
  ) : (
	<Link to={`${currentRoute.path}`}>{currentRoute.title}</Link>
  );
}

export const Breadcrumbs = ({ paths = [], basePath = 'stocks' }) => {
  const basePaths = {
    stocks: [
      {
        path: '/stocks',
        breadcrumbName: 'Stocks',
      },
      {
        path: '/stocks_sales',
        breadcrumbName: 'Stocks and Sales',
      },
    ],
    admin: [
      {
        path: '/admin',
        breadcrumbName: 'Admin',
      }
    ],
    user: [
      {
        path: '/user',
        breadcrumbName: 'User',
      }
    ],
  };

  const selectedBasePaths = basePaths[basePath] || [];

  return null;
  return (
    <Breadcrumb
      itemRender={itemRender}
      className={css.breadcrumbs}
      routes={selectedBasePaths.concat(paths)}
    />
  );
};