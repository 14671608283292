import { CSVLink } from "react-csv";

export const EscapedCSVLink = ({data, linkRef, ...props}) => {
	let escapedData = [];
	if(Array.isArray(data)) {
		for(let row of data) {
			let escapedRow = {};
			for(let column of Object.keys(row)) {
				let cell = row[column];
				if(typeof cell == 'string') {
					cell = cell.replace(/"/g, '""');
				}
				escapedRow[column] = cell;
			}
			escapedData.push(escapedRow);
		}
	}
	return <CSVLink data={escapedData} ref={linkRef} {...props} />
};
