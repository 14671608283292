import axios from "axios";
import { buildQueryString, authHeader } from "../utils/helpers";
import { persistUser } from "../store/AuthContext";
const { REACT_APP_SERVER_URL, REACT_APP_ORTHUS_URL, REACT_APP_API_BASIC_AUTH_USERNAME, REACT_APP_API_BASIC_AUTH_PASSWORD } = process.env;
const { REACT_APP_RIOT_URL, REACT_APP_RIOT_API_USER, REACT_APP_RIOT_API_TOKEN } = process.env;

//axios.defaults.auth = {username: REACT_APP_API_BASIC_AUTH_USERNAME, password: REACT_APP_API_BASIC_AUTH_PASSWORD};
//axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

const riotInstance = axios.create({
  baseURL: REACT_APP_RIOT_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Auth-Token': REACT_APP_RIOT_API_TOKEN
  },
});

riotInstance.interceptors.request.use(request => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    request.headers["X-User-Id"] = user.id;
    request.headers["X-Person-Token"] = user.token;
    request.headers["X-Data-Silo-Code"] = 'cgo';
  }
  return request;
}, error => {
  return Promise.reject(error);
});

let refreshTokenPromise;

riotInstance.interceptors.response.use(
  response => response, // Directly return successful responses.
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      //todo: login errors
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
      try {
        if(!refreshTokenPromise) {
          let user = JSON.parse(localStorage.getItem('user'));
          const access_token_ttl = localStorage.getItem("access_token_ttl")
          console.log('refresh token called');
          // Make a request to your auth server to refresh the token.
          refreshTokenPromise = riotInstance.post(`/v1/people/reset_access_token`, {access_token_ttl}, {
            headers: {"X-Person-Refresh-Token": user.refreshToken}
          }).then(({data}) => {
            console.log('refresh complete');
            refreshTokenPromise = null;
            persistUser(data);
            return Promise.resolve(data);
          });
        } else {
          console.log('refresh token call already in progress');
        }
        
        return refreshTokenPromise.then(data => {
          console.log('refresh promise resolved')
          return riotInstance(originalRequest); // Retry the original request with the new access token.
        })
        
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem('user');
        //window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error); // For all other errors, return the error as is.
  }
);

export const loginRequestOrthus = (email, password) => {
  return axios.post(`${REACT_APP_ORTHUS_URL}/v1/users/sign_in`,{ email, password });
}

export const loginRequest = (email, password, ttl) => {
  return riotInstance.post(`v1/people/sign_in`,{ email, password, access_token_ttl: ttl }, { } );
}

export const logoutRequest = (id) => {
  return riotInstance.delete(`/v1/people/sign_out?person_id=${id}`, { } );
}

export const forgotPasswordRequest = (email) => {
  return riotInstance.post(`/v1/people/recovery_password`, { person_id: email }, { });
}

export const getOverviewRequestGET = ({ page, search, sort, type }) => {
  const { search: searchTerm, format, range: {start, end}} = search;
  const { headers, imprints } = authHeader();  
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/overview?search=${searchTerm}&format=${format}&range_start=${start?start:''}&range_end=${end?end:''}&page=${page}&sort=${sort}`,
    { headers }
  );
};

export const getOverviewRequest = ({ page, search, sort, all }) => {
  const { search: searchTerm, format, range: {start, end}} = search;
  const { headers, imprints } = authHeader();  
  
  return axios.post(
    `${REACT_APP_SERVER_URL}/api/overview?&page=${page}&sort=${sort}&all=${all}`, {search, userImprints: imprints}, 
    { headers }
  );
};


export const getSalesSummaryRequest = ({ upc, type, page, filters, sort }) => {
  const { headers, imprints } = authHeader();  
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/sales/${upc}/${type}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers }
  );
};

export const getGoodsRequest = ({ upc, page, filters, sort }) => {
  const { headers, imprints } = authHeader();  
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/goods/${upc}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers }
  );
};

export const getCurrentOrdersRequest = ({ upc, page, filters, sort }) => {
  const { headers, imprints } = authHeader();  
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/orders/${upc}?filter=${filters}&page=${page}&sort=${sort}`,
    { headers }
  );
};

export const getSalesOrdersRequest = ({ upc, start, end, mode, page, filters, sort }) => {
  const { headers, imprints } = authHeader();  
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/sales_orders/${upc}/${start}/${end}?mode=${mode}&filter=${filters}&page=${page}&sort=${sort}`,
    { headers }
  );
};

export const getSearchRequest = ({ query }) => {
  const { headers, imprints } = authHeader();  
  return axios.post(
    `${REACT_APP_SERVER_URL}/api/search/${query}`,
    { userImprints: imprints },
    { headers }
  );
};

export const getAccountTypesRequest = ({ page, search, sort, status }) => {
  const { headers, imprints } = authHeader();  
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/account_types/${status}?search=${search}&page=${page}&sort=${sort}`,
    { headers }
  );
};

export const updateAccountTypeRequest = (data) => {
  const { headers, imprints } = authHeader();  
  return axios.patch(`${REACT_APP_SERVER_URL}/api/account_types`, data,
    { headers }
  );
};

export const getLabelsRequest = () => {
  return riotInstance.get(`/v1/labels`, {
  });
};

export const getUsersRequest = () => {
  const { headers, imprints } = authHeader();  
  return riotInstance.get(`/v1/people`, {
  });
};

export const getCurrentUserDetailsRequest = () => {
  const { headers, imprints } = authHeader();  
  return riotInstance.post(`/v1/people/details`, {}, {
  });
};

export const createUserRequest = (data) => {
  const { headers, imprints } = authHeader();  
  return riotInstance.post(`/v1/people`, data, {
  });
};

export const deleteUserRequest = (userId) => {  
  return riotInstance.delete(`/v1/people`, {
  });
};

export const inviteUserRequest = (userId) => { 
  return riotInstance.post(`/v1/people/send_invite`, { person_id: userId }, {
  });
};


export const updateUserRequest = (data) => {
  return riotInstance.patch(`/v1/people`, data, {
  });
};

export const updatePasswordRequest = (data) => {
  return riotInstance.post(`${REACT_APP_RIOT_URL}/v1/people/change_password`, data, {
  });
};

export const getLastUpdatesRequest = () => {
  const { headers, imprints } = authHeader();  
  return axios.get(
    `${REACT_APP_SERVER_URL}/api/report/last-updates`,
    { headers }
  );
};
