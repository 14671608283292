import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "./forgotPasswordSlice";

import { Flex, Form, Input, Button } from "antd";

import css from "../Dashboard/Dashboard.module.css";

export const ForgotPassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [formSent, setFormSent] = useState(false);
  const onSubmit = async (values) => {
    await dispatch(forgotPassword(values.email.trim()));
    form.resetFields();
		setFormSent(true)
		setTimeout(() => {
			navigate("/login");
		}, 5000)
  };
  
  return (
		<Flex className={css.root} vertical align="center">
      <div className={"ibox-holder"} style={{width: '100%', maxWidth: '600px'}}>
				{!formSent ? <>
					<h1 style={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}}>Reset Password</h1>
						<Form
						name="forgotPassword"
						style={{
							maxWidth: 600,
							width: '100%'
						}}
						initialValues={{
							remember: true,
						}}
						onFinish={onSubmit}
						autoComplete="off"
					>

					<Form.Item
						label="Email"
						name="email"
						rules={[
							{
								required: true,
								message: 'Email is required',
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 4,
							span: 16,
						}}
					>
						<Button type="primary" htmlType="submit">
							Reset password
						</Button>
					</Form.Item>
				</Form>
			</> : <>An email has been sent to you. Please follow the instructions to reset your password.</>}
		</div>
	</Flex>
  );
};
