import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../store/AuthContext";
import { Link } from "react-router-dom";

import { Col, Flex, Form, Input, Checkbox, Button, Row } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Notification } from "../../components/Notification/Notification";

import css from "../Dashboard/Dashboard.module.css";

export const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const { login, user, error, loading } = useAuth(); 
  const [showNotification, setShowNotification] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState("");
  
  useEffect(() => {
  	if (user) {
		navigate("/");
  	}
  }, [user]);

	useEffect(() => {
    if (error) {
      setShowNotification(true);
      setNotificationMessage(error);
    }

    if (location.search.includes('?from=password_recovery')) {
      setShowNotification(true);
      setNotificationMessage("Your password was changed successfully, please log in");
    }
  }, [error, location.search]);
  
  const onFinish = (values) => {
	// token ttl, 5 min / 1 month  
	const ttl = values.remember ? 2628288 : 30;  
  	login(values.email, values.password, ttl);	
  };
  
  const onFinishFailed = (errorInfo) => {
  	//console.log('Failed:', errorInfo);
  };
  
  return (
	<Flex className={css.root} vertical align="center">
		<Notification
			mode={error ? "error" : "success"}
			message={error ? "Login Error" : "Notification"}
			description={notificationMessage}
			showNotification={showNotification}
			cancel={() => setShowNotification(false)}
		/>

		<Form
			name="basic"
			labelCol={{
				span: 8,
			}}
			wrapperCol={{
				span: 16,
			}}
			style={{
				maxWidth: 600,
				width: '100%'
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			>

			<Form.Item
				label="Email"
				name="email"
				rules={[
					{
						required: true,
						message: 'Email is required',
					},
				]}
			>
				<Input />
			</Form.Item>
		
			<Form.Item
				label="Password"
				name="password"
				rules={[
					{
						required: true,
						message: 'Password is required',
					},
				]}
			>
				<Input.Password />
			</Form.Item>
			<Form.Item
				name="remember"
				valuePropName="checked"
				wrapperCol={{
					offset: 8,
					span: 16,
				}}
			>
			<Checkbox>Remember me</Checkbox>
			</Form.Item>
			<Form.Item
				wrapperCol={{
					offset: 8,
					span: 16,
				}}
			>
				<Button type="primary" htmlType="submit">
					Submit
				</Button>
			</Form.Item>
			<Form.Item
					name="forgotPassword"
					wrapperCol={{
						offset: 8,
						span: 16,
					}}
				>
				<Link to='/forgot_password'>Forgot password?</Link>
			</Form.Item>
		</Form>
	</Flex>
  );
};
