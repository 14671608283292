import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginRequest, logoutRequest } from "../api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
	const currentUser = JSON.parse(localStorage.getItem("user"));
	
	if (currentUser?.token) {
	  setUser(currentUser);
	} else {
	  setIsAuthenticated(false);
	}
  }, []);

  const login = async (email, password, ttl = 2628288) => {
	setLoading(true);
	setError(null);

	try {
	  // Make API request to authenticate user
	  const response = await loginRequest(email, password, ttl);
	  const { data } = response;
	  if (data.status == 'ok') {
		const user = persistUser(data);
		localStorage.setItem("access_token_ttl", ttl);
		
		const { role, labels: label_ids } = user; // todo: replace
		if(Array.isArray(label_ids) && label_ids.length > 0) {
			user.imprint_ids = label_ids.join(',');
		}
		else {
			user.imprint_ids = [];
		}

		setUser(user);
		setLoading(false);
	  } else {
		setLoading(false);
		setError("Sorry, there is an error with your email or password");
	  }
	} catch (error) {
		console.log(error);
	  setLoading(false);
	  setError("Sorry, there is an error with your email or password");
	}
  };

  const logout = async () => {
	// Clear user state and remove token
	try {
		const response = await logoutRequest(user.id);
	}
	catch(error) {
		console.log('logout API error');
	}
	setUser(null);
	setIsAuthenticated(false);
	localStorage.removeItem("user")
  };

  useEffect(() => {
	if (!user && location.pathname === "/") {
	  navigate("/login");
	}
  }, [user]);

  return (
	<AuthContext.Provider
	  value={{ user, login, logout, loading, isAuthenticated, error }}
	>
	  {children}
	</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
	throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const persistUser = (data) => {
	const user = {
		token: data.access_token,
		refreshToken: data.refresh_token,
		...data.person
	}
	localStorage.setItem("user", JSON.stringify(user));
	return user;
}

export { AuthProvider, useAuth, persistUser };