import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
  getUsersRequest, 
  getCurrentUserDetailsRequest,
  updateUserRequest,
  createUserRequest,
  deleteUserRequest,
  updatePasswordRequest,
  inviteUserRequest
} from "../../api";

const initialState = {
  users: [],
  getUsersInProgress: false,
  getUsersError: null, 
  updateUserInProgress: false,
  updateUserError: null,
  deleteUserInProgress: false,
  deleteUserError: null,
  createUserInProgress: false,
  createUserError: null,
  currentUser: [],
  getCurrentUserDetailsInProgress: false,
  getCurrentUserDetailsError: null,
  updatePasswordInProgress: false,
  updatePasswordError: null,
  inviteUserInProgress: false,
  inviteUserError: null
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUsersRequest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = _;
      const response = await updateUserRequest(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = _;
      const response = await createUserRequest(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await deleteUserRequest(userId);
      return { userId, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const inviteUser = createAsyncThunk(
  "users/inviteUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await inviteUserRequest(userId);
      return { userId, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCurrentUserDetails = createAsyncThunk(
  "users/getCurrentUserDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCurrentUserDetailsRequest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updatePasswordRequest(data);
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearData(state) {
      state.users = [];
      state.currentUser = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.getUsersInProgress = true;
        state.getUsersError = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const payload = action.payload;
        const userData = payload.people || [];

        state.users = { data: userData };
        state.getUsersInProgress = false;
        state.getUsersError = null;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.getUsersInProgress = false;
        state.getUsersError = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.updateUserInProgress = true;
        state.updateUserError = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserInProgress = false;
        state.updateUserError = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserInProgress = false;
        state.updateUserError = action.payload;
      })
      .addCase(createUser.pending, (state) => {
        state.createUserInProgress = true;
        state.createUserError = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUserInProgress = false;
        state.createUserError = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserInProgress = false;
        state.createUserError = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleteUserInProgress = true;
        state.deleteUserError = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const { userId } = action.payload;
        if(state.users && state.users.data) {
          state.users.data = state.users.data.filter(user => user.id !== userId);
        }
        state.deleteUserInProgress = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserInProgress = false;
        state.deleteUserError = action.payload;
      })
      .addCase(getCurrentUserDetails.pending, (state) => {
        state.getCurrentUserDetailsInProgress = true;
        state.getCurrentUserDetailsError = null;
      })
      .addCase(getCurrentUserDetails.fulfilled, (state, action) => {
        const payload = action.payload;
        const userData = payload.person || {};

        state.currentUser = { data: userData };
        state.getCurrentUserDetailsInProgress = false;
        state.getCurrentUserDetailsError = null;
      })
      .addCase(getCurrentUserDetails.rejected, (state, action) => {
        state.getCurrentUserDetailsInProgress = false;
        state.getCurrentUserDetailsError = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.updatePasswordInProgress = true;
        state.updatePasswordError = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.updatePasswordInProgress = false;
        state.updatePasswordError = null;
        state.updatePasswordMessage = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.updatePasswordInProgress = false;
        state.updatePasswordError = action.payload;
      })
      .addCase(inviteUser.pending, (state) => {
        state.inviteUserInProgress = true;
        state.inviteUserError = null;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        const { userId } = action.payload;
        state.inviteUserInProgress = false;
      })
      .addCase(inviteUser.rejected, (state, action) => {
        state.inviteUserInProgress = false;
        state.inviteUserError = action.payload;
      })

  },
});

export const { clearData } = usersSlice.actions;

export default usersSlice.reducer;
