import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate, Link } from "react-router-dom";
import { Col, Form, Input, Row, Select, Button } from "antd";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { MainButton } from "../../components/MainButton/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { rolesOptions } from "../../constants/roles";
import { createUser } from "../Users/usersSlice";
import { getLabels, clearData } from "../Users/labelsSlice";
import MultiSelectTransfer from "../../components/MultiSelectTransfer/MultiSelectTransfer";

import css from "./CreateUser.module.css";

export const CreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = useForm();

  const [selectedLabelIds, setSelectedLabelIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const { labels, getLabelsInProgress } =
    useSelector((state) => state?.labels) || {};

  const onSubmit = async (values) => {
    await dispatch(
      createUser({
        data: {
          ...values,
          label_ids: selectedLabelIds,
        },
      })
    );
    form.resetFields();
    navigate("/admin/users", { state: { from: "/userCreated" } });
  };

  useEffect(() => {
    dispatch(getLabels());
    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  const rules = [
    {
      required: true,
      message: "This field is required",
    },
  ];

  return (
    <>
      <Breadcrumbs
        basePath="admin"
        paths={[
          { path: `/admin/users/`, breadcrumbName: `Users Management` },
          { path: `/users/create`, breadcrumbName: `Create User` },
        ]}
      />
      <PageHeader title="Create User" />

      <div className={css.root}>
        <div className={"ibox-holder"}>
          <Form
            form={form}
            name="createUserForm"
            style={{ marginTop: 10, marginBottom: 0 }}
            autoComplete="off"
            layout="vertical"
            onFinish={onSubmit}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  className={css.formItem}
                  label={<span className={css.label}>Email</span>}
                  rules={rules}
                >
                  <Input
                    size="large"
                    placeholder="Email"
                    style={{ color: "#737373" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  className={css.formItem}
                  label={<span className={css.label}>Name</span>}
                  rules={rules}
                >
                  <Input
                    size="large"
                    placeholder="Name"
                    style={{ color: "#737373" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="role"
                  className={css.formItem}
                  label={<span className={css.label}>Role</span>}
                  rules={rules}
                >
                  <Select
                    allowClear
                    placeholder="Role"
                    size="large"
                    options={rolesOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={12}>
                <Form.Item
                  name="labels"
                  className={css.formItem}
                  label={<span className={css.label}>Labels</span>}
                >
                  <MultiSelectTransfer
                    availableOptions={labels}
                    selectedOptions={labels.filter((label) =>
                      selectedLabelIds.includes(label.id)
                    )}
                    onChange={(selected) =>
                      setSelectedLabelIds(selected.map((label) => label.id))
                    }
                    labelKey="name"
                    valueKey="id"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={12} xl={6}>
                <Row gutter={8}>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Form.Item>
                      <MainButton
                        size="large"
                        rootClassName={css.submitButton}
                        htmlType="submit"
                        loading={false}
                      >
                        Create User
                      </MainButton>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                      <Button type="default" size="large" className={css.backButton}>
                        <Link to="/admin/users">Back</Link>
                      </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
