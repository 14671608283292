import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Flex, Form, Input, Row, Table, DatePicker, Radio, Alert, Tooltip } from "antd";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { getSalesOrders } from "../Dashboard/dashboardSlice";
import { buildQueryString, debounce } from "../../utils/helpers";
import { HeaderCell } from "../Dashboard/HeaderCell";
import { IconDownload } from "../../components/IconDownload/IconDownload";
import { IconSearch } from "../../components/IconSearch/IconSearch";
import { MainButton } from "../../components/MainButton/MainButton";
import { Notification } from "../../components/Notification/Notification";
import { dateCell } from "../../components/DateCell/DateCell";
import { valueCell } from "../../components/ValueCell/ValueCell";
import { generateDateRange, generateMonthRange } from "../../utils/helpers";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import css from "../Dashboard/Dashboard.module.css";

const { RangePicker } = DatePicker;
const monthBreakpoint = 30;

export const SalesOrders = ({upc}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [range, setRange] = useState({
	  start: dayjs().subtract(1, 'week').add(1, 'day').format('YYYY-MM-DD'),
	  end: dayjs().format('YYYY-MM-DD'), 
	  mode: 'day'
  });
  const [showWarning, setShowWarning] = useState(false);
  const [ preset, setPreset ] = useState('week');
  
  const { salesOrders, getSalesOrdersInProgress } =
	useSelector((state) => state?.dashboard) || {};

  const { data: rows, totalPages } = salesOrders || {};

  useEffect(() => {
	dispatch(
	  getSalesOrders({
		upc,
		start: range.start,
		end: range.end,  
		mode: range.mode,  
	  }),
	);
  }, [upc, range]);
  
  const handleDateChange = (dates) => {
	if(!dates) {
		return datePreset('week')
	}
  	const [start, end] = dates || [];
	const diff = end.diff(start, 'day');
	const mode = diff > monthBreakpoint ? 'mth' : 'day';
	setRange({start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), mode});
  };
  
  const generateRange = () => {
	const { start, end, mode } = range;  
	return mode == 'mth' ? generateMonthRange(start, end) : generateDateRange(start, end);
  }
  
  const datePreset = (preset) => {
	  const end = dayjs();
	  let start, dateWarning;
	  if(preset != 'all') {
	  	start = end.subtract(1, preset).add(1, 'day');
		dateWarning = false;
	  }
	  else {
	  	start = end.subtract(5, 'year');
		dateWarning = true;
	  }  
	  setPreset(preset);
	  setShowWarning(dateWarning);
	  return handleDateChange([start, end]);
  }

  let columns = [
	/*  
	{
	  title: "UPC",
	  editable: true,
	  dataIndex: "upc",
	  width: 250,
	  sorter: true,
	  align: "left",
		showSorterTooltip: false,
	},
	*/
	{
	  title: (
			<Tooltip title="Catalogue Number">
				<div>Catalogue Number</div>
			</Tooltip>
		),
	  editable: true,
	  dataIndex: "Catalogue_Number",
	  sorter: true,
	  width: 200,
	  align: "left",
		showSorterTooltip: false,
	},

	{
	  title: "Total",
	  editable: true,
	  dataIndex: "total",
	  width: 150,
	  align: "left",
		showSorterTooltip: false,
	  // hide: preset != 'all'
	},

  ];
  for(let date of generateRange()) {
	  columns.push({
	  	title: date,
		editable: true,
		dataIndex: date,
		width: 150,
		align: "left",
		render: (record) => valueCell(record)
	  })
  }
  const initialDates = [dayjs(range.start), dayjs(range.end)];

  return (
		<Flex className={""} vertical>
			<SectionHeader title="Sale By Date">
				<CSVLink data={rows || []} filename="sales_orders.csv">
					<IconDownload />
				</CSVLink>
			</SectionHeader>
			<div className={css.sectionDatepickerFilterHolder}>
				<RangePicker format="DD-MM-YYYY"
					size={"large"}
					value={initialDates}
					onChange={handleDateChange}
				/>
				<Radio.Group value={preset} onChange={(e)=>datePreset(e.target.value)}>
				<Radio.Button value="week">Last 7 days</Radio.Button>
				<Radio.Button value="month">Month to Date</Radio.Button>
				<Radio.Button value="year">Last 12 Months</Radio.Button>
				<Radio.Button value="all">All Time</Radio.Button>
			</Radio.Group>

			</div>
			{showWarning && <Alert message="Please note: legacy sales data is included prior to Jan 1 2025" type="warning" closable afterClose={()=>setShowWarning(false)} />}
			<Table
				components={{
					header: {
						row: HeaderCell,
					},
				}}
				columns={columns.filter(c=>c.hide !== true)}
				rowKey={(record) => record.id}
				dataSource={rows}
				pagination={{
					position: ["bottomRight"],
					responsive: true,
					total: totalPages,
					pageSize: 10,
					hideOnSinglePage: true,
					showSizeChanger: false,
				}}
				loading={getSalesOrdersInProgress}
				style={{ marginTop: 10 }}
				scroll={{ x: "100%" }}
			/>
		</Flex>
  );
};
